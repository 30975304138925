import * as React from "react";
import Layout from "../components/reusable/Layout";
import { Link, graphql } from "gatsby";
import { useEffect } from "react";
import Seo from "../components/reusable/Seo";
import { useTranslation } from "gatsby-plugin-react-i18next";
import {
  CompaniesSection,
  ContactSection,
  CostumerSection,
  HeroSection,
  ImpactSection,
  ResultsSection,
  ServicesSection,
  ServicesSectionInfo,
  SolutionsSection,
  TalentSection,
} from "../styles/pages/home";
import { Bg, Text } from "../components/ui";
import { Button, Stack, Box } from "@mui/material";
import calendarClick from "../images/home/calendar-click.png";
import clockClick from "../images/home/clock-click.png";
import desktopClick from "../images/home/desktop-click.png";
import starClick from "../images/home/star-click.png";
import userClick from "../images/home/user-click.png";
import usersClick from "../images/home/users-click.png";
import windowClick from "../images/home/window-click.png";
import userStarClick from "../images/home/user-star-click.png";
import cert from "../images/home/cert-b.png";
import forbes from "../images/home/forbes-30.png";
import Carousel from "../components/reusable/Carousel";
import CarouselTestimonials from "../components/reusable/CarouselTestimonials";
import { GradientBackground } from "../../../lapieza-backend/src/ui/background";

const dermavanBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FDermavan%20Negro.png?alt=media&token=824f3023-ea05-41aa-adb8-9979dddf73d9";
const flatBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FFlat%20Negro.png?alt=media&token=3ac0b29b-c34e-422f-8349-8e38eff2f640";
const leadgeniosBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FlEADGENIOS%20Negro.png?alt=media&token=92aba62d-ab6c-404f-8c0a-27fca2129dfb";
const echaleBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FLOGO-E%CC%81chale-09%20Negro.png?alt=media&token=0e146370-0bab-4086-b4c7-4e2719cfaedd";
const walliaBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FWallia%20Negro.png?alt=media&token=4d6b80e1-db56-4af0-ac3a-f9868de38f9b";
const grupo =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FGrupo%2026767.png?alt=media&token=a376f89d-5c80-449d-90da-ff619d800755";
const pieza =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2F%2B350%2C000%20personas%20registradas.png?alt=media&token=07588d58-84fa-4aef-a935-42afc769d36c";
const time =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FTalento%20en%20tiempo%20re%CC%81cord.png?alt=media&token=8a76431f-244f-4144-bdd6-179813371f4a";
const lugar =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FTodo%20desde%20un%20solo%20lugar.png?alt=media&token=45271423-c588-4df0-ad95-ab43a9f70321";
const imageATS =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FApplicant%20Tracking%20System.png?alt=media&token=68b8b23b-c6cf-4711-a16e-ce8c3a7f48e3";
const headhunting =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FHeadhunting.png?alt=media&token=9499700e-4e5d-4551-aa16-44d3bb832203";
const solutions =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2F%C2%BFAu%CC%81n%20no%20sabes%20que%CC%81%20solucio%CC%81n%20te%20conviene%20ma%CC%81s_.png?alt=media&token=1c1ac53c-6624-4644-886a-38d1b7b16b9e";
const impact =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FNos%20mueve%20el%20impacto%201.png?alt=media&token=66b27e7a-c563-40cf-a383-c5919cbdebf1";
const carbonNeutral =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2Fwe%20are%20carbon%20neutral.png?alt=media&token=d480d57f-bfe1-4bdf-9b60-1d34e762ac43";
const impact2 =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FNos%20mueve%20el%20impacto%202.png?alt=media&token=134e8db0-ed7e-44a0-9e05-155ed45a6ee2";
const App = () => {
  const { t } = useTranslation("home");

  // Redirects if user comes from Google integration in ATS
  useEffect(() => {
    const integrationUrl = window.location.href;
    if (window.location.href.includes("www.googleapis.com")) {
      const atsUrl = integrationUrl.replace(
        "https://empresas.lapieza.io/",
        "https://ats.lapieza.io/"
      );
      window.open(atsUrl, "_self");
    }
  }, []);

  return (
    <Layout modeHeader="dark">
      <Seo image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fpreviews%2FLANDING%20EMPRESAS.png?alt=media&token=3dd56d07-4544-4eef-ab5c-5b53a475137e" />
      <Bg.Gradient>
        <HeroSection className="hero-section">
          <div className="hero-section main-landing-container-row">
            <article>
              <Text.Title
                text={t("heroTitle1")}
                fontSize="42px"
                textAlign="start"
              />
              <Text.Title fontSize="42px" textAlign="start">
                <span
                  style={{
                    background: "#F7AD19",
                    color: "#fff",
                    borderRadius: "22px",
                    padding: "0 10px",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    width: "100%",
                  }}
                >
                  {t("heroTitle2")}
                </span>
              </Text.Title>
              <Text.Title
                text={t("heroTitle3")}
                fontSize="42px"
                textAlign="start"
              />
              <Text.Title fontSize="42px" textAlign="start">
                <span
                  style={{
                    background: "#F7AD19",
                    color: "#fff",
                    borderRadius: "22px",
                    padding: "0 10px",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    width: "100%",
                  }}
                >
                  {t("heroTitle4")}
                </span>
              </Text.Title>
              <Text.Title
                text={t("heroTitle5")}
                fontSize="42px"
                textAlign="start"
              />
              <Text.Body
                text={t("heroDesc")}
                sx={{
                  fontSize: "18px",
                  textAlign: "start",
                  marginTop: "20px",
                  display: "block",
                }}
              />

              <Button
                href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  marginTop: "50px",
                  width: "280px",
                  fontSize: "16px",
                  mb: 5,
                }}
              >
                <Text.Body
                  text={t("moreInformation")}
                  sx={{
                    color: "primary.white",
                    fontSize: "16px",
                  }}
                />
              </Button>
            </article>
            <figure className="hero-image">
              <img src={grupo} alt="LaPieza" />
            </figure>
          </div>
        </HeroSection>
      </Bg.Gradient>
      <CompaniesSection>
        <div className="companies-section">
          <Carousel
            logos={[
              dermavanBlack,
              flatBlack,
              leadgeniosBlack,
              echaleBlack,
              walliaBlack,
            ]}
          />
        </div>
      </CompaniesSection>
      <TalentSection>
        <Stack alignItems="center">
          <Stack maxWidth="500px" textAlign="center" marginBottom="120px">
            <Text.Title text={t("talentTitle")} fontSize="38px" />
          </Stack>
          <Stack
            className="info-list"
            direction={{ xs: "column", md: "row" }}
            marginTop="20px"
            justifyContent="space-between"
            alignItems="start"
            spacing={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              marginBottom={"40px"}
            >
              <img
                src={pieza}
                alt="Ilustración de LaPieza"
                style={{
                  width: "100px",
                  height: "auto",
                  alignSelf: "center",
                  marginBottom: "20px",
                }}
              />
              <Text.Title
                text={t("talentItemTitle1")}
                fontSize="18px"
                textAlign="start"
              />
              <Text.Body
                text={t("talentItemDescription1")}
                fontSize="16px"
                textAlign="start"
              />
            </Box>

            <Box display="flex" flexDirection="column" gap={2}>
              <img
                src={time}
                alt="Ilustración de LaPieza"
                style={{
                  width: "140px",
                  height: "auto",
                  alignSelf: "center",
                  marginBottom: "20px",
                }}
              />
              <Text.Title
                text={t("talentItemTitle2")}
                fontSize="18px"
                textAlign="start"
              />
              <Text.Body
                text={t("talentItemDescription2")}
                fontSize="16px"
                textAlign="start"
              />
            </Box>

            <Box display="flex" flexDirection="column" gap={2}>
              <img
                src={lugar}
                alt="Ilustración de LaPieza"
                style={{
                  width: "132px",
                  height: "auto",
                  alignSelf: "center",
                  marginBottom: "20px",
                }}
              />
              <Text.Title
                text={t("talentItemTitle3")}
                fontSize="18px"
                textAlign="start"
              />
              <Text.Body
                text={t("talentItemDescription3")}
                fontSize="16px"
                textAlign="start"
              />
            </Box>
          </Stack>
        </Stack>
      </TalentSection>

      <ServicesSection>
        <Stack alignItems="center">
          <Text.SubTitle
            text={t("servicesTitle")}
            color="primary.orange"
            fontSize="16px"
            textAlign="start"
          />
          <h3>
            <Text.Title text={t("servicesSubTitle")} fontSize="42px" />
          </h3>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={0.5}
            maxWidth="2000px"
            className="info-section"
          >
            <Bg.Linear
              sx={{
                borderRadius: "9px",
                minHeight: "400px",
                maxWidth: "1200px",
              }}
            >
              <article>
                <Text.SubTitle
                  text={t("servicesItemSubtitle1")}
                  color="primary.orange"
                  fontSize="16px"
                  textAlign="start"
                />
                <Box
                  sx={{
                    paddingRight: "50px",
                  }}
                >
                  <Box sx={{ maxWidth: 700, textAlign: "start" }}>
                    <Text.Title
                      text={t("servicesItemTitle1")}
                      fontSize="28px"
                      textAlign="start"
                    />
                  </Box>

                  <Box
                    sx={{
                      maxWidth: 350,
                      textAlign: "start",
                      marginTop: "30px",
                    }}
                  >
                    <Text.Body
                      text={t("servicesItemDescription1")}
                      fontSize="15px"
                      textAlign="start"
                    />
                  </Box>
                </Box>
                <Link to="/ats">
                  <Text.SubTitle
                    text={t("seeMoreButton")}
                    color="primary.orange"
                    fontSize="16px"
                  />
                  <Text.SubTitle color="primary.orange" fontSize="16px">
                    {" "}
                    {" >"}
                  </Text.SubTitle>
                </Link>
                <figure>
                  <img src={imageATS} alt="ATS LaPieza" />
                </figure>
              </article>
            </Bg.Linear>

            <Bg.Linear
              sx={{
                borderRadius: "9px",
                minHeight: "400px",
              }}
            >
              <article>
                <Text.SubTitle
                  text={t("servicesItemSubtitle2")}
                  color="primary.orange"
                  fontSize="16px"
                  textAlign="start"
                />
                <Box>
                  <Box sx={{ maxWidth: 400, textAlign: "start" }}>
                    <Text.Title
                      text={t("servicesItemTitle2")}
                      fontSize="28px"
                      textAlign="start"
                    />
                  </Box>

                  <Box
                    sx={{
                      maxWidth: 350,
                      textAlign: "start",
                      marginTop: "70px",
                    }}
                  >
                    <Text.Body
                      text={t("servicesItemDescription2")}
                      fontSize="15px"
                      textAlign="start"
                    />
                  </Box>
                </Box>
                <Link to="/headhunting">
                  <Text.SubTitle
                    text={t("seeMoreButton")}
                    color="primary.orange"
                    fontSize="16px"
                  />
                  <Text.SubTitle color="primary.orange" fontSize="16px">
                    {" "}
                    {" >"}
                  </Text.SubTitle>
                </Link>
                <figure
                  style={{
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    margin: "0",
                  }}
                >
                  <img src={headhunting} alt="ATS LaPieza" />
                </figure>
              </article>
            </Bg.Linear>
          </Stack>
        </Stack>
      </ServicesSection>

      <Bg.Linear
        sx={{
          marginBottom: "50px",
        }}
      >
        <ServicesSectionInfo>
          <div className="main-landing-container-column">
            <Box sx={{ marginTop: "10px", marginBottom: 7 }}>
              <Text.Title text={t("servicesInfoTitle")} fontSize={"38px"} />
            </Box>
            <div className="info-section">
              <div className="info-section-item">
                <Box
                  sx={{
                    marginTop: "10px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  <Text.SubTitle
                    text={t("servicesInfoAtsTitle")}
                    color={"primary.orange"}
                    fontSize={"16px"}
                  />
                </Box>

                <div className="info-section-column">
                  <article>
                    <img src={userClick} alt="Icon LaPieza" />
                    <Box>
                      <h3>{t("servicesInfoAtsItemTitle1")}</h3>
                      <Text.Body
                        text={t("servicesInfoAtsItemDescription1")}
                        fontSize="15px"
                        textAlign="start"
                        sx={{ display: "block" }}
                      />
                    </Box>
                  </article>
                  <article>
                    <img src={desktopClick} alt="Icon LaPieza" />
                    <Box>
                      <h3>{t("servicesInfoAtsItemTitle2")}</h3>
                      <Text.Body
                        text={t("servicesInfoAtsItemDescription2")}
                        fontSize="15px"
                        textAlign="start"
                        sx={{ display: "block" }}
                      />
                    </Box>
                  </article>
                  <article>
                    <img src={windowClick} alt="Icon LaPieza" />
                    <Box>
                      <h3>{t("servicesInfoAtsItemTitle3")}</h3>
                      <Text.Body
                        text={t("servicesInfoAtsItemDescription3")}
                        fontSize="15px"
                        textAlign="start"
                        sx={{ display: "block" }}
                      />
                    </Box>
                  </article>
                  <article>
                    <img src={usersClick} alt="Icon LaPieza" />
                    <Box>
                      <h3>{t("servicesInfoAtsItemTitle4")}</h3>
                      <Text.Body
                        text={t("servicesInfoAtsItemDescription4")}
                        fontSize="15px"
                        textAlign="start"
                        sx={{ display: "block" }}
                      />
                    </Box>
                  </article>
                </div>
              </div>
              <div className="info-section-item">
                <Box
                  sx={{
                    marginTop: "10px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  <Text.SubTitle
                    text={t("servicesInfoHeadHuntingTitle")}
                    color={"primary.orange"}
                    fontSize={"16px"}
                  />
                </Box>

                <div className="info-section-column">
                  <article>
                    <img src={userStarClick} alt="Icon LaPieza" />
                    <Box>
                      <h3>{t("servicesInfoHeadHuntingItemTitle1")}</h3>
                      <Text.Body
                        text={t("servicesInfoHeadHuntingItemDescription1")}
                        fontSize="15px"
                        textAlign="start"
                        sx={{ display: "block" }}
                      />
                    </Box>
                  </article>
                  <article>
                    <img src={clockClick} alt="Icon LaPieza" />
                    <Box>
                      <h3>{t("servicesInfoHeadHuntingItemTitle2")}</h3>
                      <Text.Body
                        text={t("servicesInfoHeadHuntingItemDescription2")}
                        fontSize="15px"
                        textAlign="start"
                        sx={{ display: "block" }}
                      />
                    </Box>
                  </article>
                  <article>
                    <img src={calendarClick} alt="Icon LaPieza" />
                    <Box>
                      <h3>{t("servicesInfoHeadHuntingItemTitle3")}</h3>
                      <Text.Body
                        text={t("servicesInfoHeadHuntingItemDescription3")}
                        fontSize="15px"
                        textAlign="start"
                        sx={{ display: "block" }}
                      />
                    </Box>
                  </article>
                  <article>
                    <img src={starClick} alt="Icon LaPieza" />
                    <Box>
                      <h3>{t("servicesInfoHeadHuntingItemTitle4")}</h3>
                      <Text.Body
                        text={t("servicesInfoHeadHuntingItemDescription4")}
                        fontSize="15px"
                        textAlign="start"
                        sx={{ display: "block" }}
                      />
                    </Box>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </ServicesSectionInfo>
      </Bg.Linear>

      <Bg.Gradient>
        <SolutionsSection>
          <div className="main-landing-container-row">
            <Box>
              <article>
                <Box>
                  <Text.Title
                    sx={{
                      display: "block",
                      marginTop: "20px",
                      marginBottom: "30px",
                      fontSize: "38px",
                      textAlign: "start",
                    }}
                    text={t("solutionsTitle")}
                  />
                  <Text.Body
                    text={t("solutionsDescription")}
                    fontSize={"18px"}
                  />
                </Box>
              </article>
              <Button
                href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  width: "280px",
                  fontSize: "16px",
                  mb: 5,
                  marginTop: "30px",
                }}
              >
                <Text.Body
                  text={t("moreInformation")}
                  sx={{
                    color: "primary.white",
                    fontSize: "16px",
                    textAlign: "start",
                  }}
                />
              </Button>
            </Box>
            <figure>
              <img src={solutions} alt="Servicios LaPieza" />
            </figure>
          </div>
        </SolutionsSection>
      </Bg.Gradient>

      <ImpactSection>
        <div className="main-landing-container-column">
          <Box
            sx={{
              maxWidth: "457px",
              marginBottom: "70px",
              textAlign: "center",
            }}
          >
            <Text.Title
              text={t("impactTitle")}
              fontSize={"38px"}
              sx={{ display: "block", marginBottom: "20px" }}
            />
            <Text.Body text={t("impactDescription")} fontSize={"18px"} />
          </Box>
          <div className="impact-section">
            <img className="figure-one" src={impact} alt="Servicios LaPieza" />
            <figure className="cert-impact">
              <img className="figure-two" src={cert} alt="Empresa B LaPieza" />
              <img
                className="figure-carbon"
                src={carbonNeutral}
                alt="Empresa B LaPieza"
              />
            </figure>
            <img
              className="figure-three"
              src={impact2}
              alt="Carbon Neutral LaPieza"
            />
          </div>
          <Button
            href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              width: "191px",
              fontSize: "16px",
              mb: 5,
              marginTop: "30px",
            }}
          >
            <Link to="/impact">
              <Text.Body text={t("seeMoreButton")} color={"primary.white"} />
            </Link>
          </Button>
        </div>
      </ImpactSection>
      <ResultsSection>
        <div className="main-landing-container-column">
          <article className="main-title">
            <Text.Title text={t("resultsTitle")} fontSize={"38px"} />
            <figure>
              <img
                src={forbes}
                alt="Forbes 30 promeas de los negocios 2022 LaPieza"
              />
            </figure>
          </article>
          <div className="info-list">
            <article>
              <Text.Title
                text={t("resultsItemTitle1")}
                fontSize={"65px"}
                sx={{ display: "block" }}
                textAlign="start"
              />
              <Text.Body
                text={t("resultsItemDescription1")}
                fontSize={"24px"}
              />
            </article>
            <article>
              <Text.Title
                text={t("resultsItemTitle2")}
                fontSize={"65px"}
                sx={{ display: "block" }}
                textAlign="start"
              />
              <Text.Body
                text={t("resultsItemDescription2")}
                fontSize={"24px"}
              />
            </article>
            <article>
              <Text.Title
                text={t("resultsItemTitle3")}
                fontSize={"65px"}
                sx={{ display: "block" }}
                textAlign="start"
              />
              <Text.Body
                text={t("resultsItemDescription3")}
                fontSize={"24px"}
              />
            </article>
          </div>
        </div>
      </ResultsSection>
      <Bg.Linear>
        <CostumerSection>
          <Stack>
            <Text.Title
              text={t("costumerTitle")}
              fontSize={"38px"}
              textAlign="start"
              sx={{ marginBottom: "50px" }}
            />
            <CarouselTestimonials />
          </Stack>
        </CostumerSection>
      </Bg.Linear>
      <ContactSection>
        <div className="main-landing-container-column">
          <Stack sx={{ maxWidth: "655px", marginBottom: "80px" }}>
            <Text.Title text={t("contactTitle")} fontSize={"38px"} />
          </Stack>
          <div className="button-group">
            <Button
              href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                width: "280px",
                fontSize: "16px",
                mb: 5,
              }}
            >
              <Text.Body
                text={t("moreInformation")}
                sx={{
                  color: "primary.white",
                  textAlign: "center",
                }}
              />
            </Button>
            <Link className="see-more-button" to="/contact">
              <Text.Body text={t("teamContact")} fontSize={"16px"} />
            </Link>
          </div>
        </div>
      </ContactSection>
    </Layout>
  );
};

export default App;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

